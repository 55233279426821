<template>
  <div class="container-fluid m-0 p-0">
    <div class="row m-0 p-3">
      <div class="col-12 m-0 p-0 shadow mb-5 bg-white rounded">
        <div class="row m-0 p-3">
          <div class="col-12 col-xl-6 mx-0 mb-3 mb-xl-0">
            <p class="font-1-2s font-600">Create User Login</p>
          </div>
        </div>

        <!-- --------------- -->

        <div class="row m-0 p-3">
          <div class="col-12 col-xl-4 mb-3">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Frist Name</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control "
                  :class="[data.firstname === '' ? 'is-invalid' : '']"
                  v-model="data.firstname"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 mb-3">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Last Name</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  :class="[data.lastname === '' ? 'is-invalid' : '']"
                  v-model="data.lastname"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 mb-3">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">User Name</label>
              <div class="col-sm-9">
                <input
                  type="text"
                  class="form-control"
                  :class="[data.username === '' ? 'is-invalid' : '']"
                  v-model="data.username"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 mb-3">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Password</label>
              <div class="col-sm-9 input-group">
                <input
                  :type="view === true ? 'text' : 'password'"
                  class="form-control"
                  :class="[data.password === '' ? 'is-invalid' : '']"
                  v-model="data.password"
                />
                <div class="input-group-append">
                  <button
                    v-if="view"
                    type="button"
                    class=" btn bt-bnb"
                    @click="view = !view"
                  >
                    <span v-html="$svg_icon.view"> </span>
                  </button>
                  <button
                    v-else
                    type="button"
                    class="  btn btn-danger"
                    @click="view = !view"
                  >
                    <span v-html="$svg_icon.no_view"> </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-xl-4 mb-3">
            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Email</label>
              <div class="col-sm-9">
                <input type="text" class="form-control" v-model="data.email" />
              </div>
            </div>
          </div>
          <!-- ------------------------- -->
          <div class="col-12 text-center">
            <button type="button" class="btn bt-main px-3" @click="save">
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateUserLogin",
  data() {
    return {
      view: false,
      data: {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        email: "",
      },
    };
  },
  methods: {
    async validate() {
      return new Promise(async (resolve, reject) => {
        let { username, password, firstname, lastname, email } = this.data;
        if (!!!firstname) {
          resolve(`Please enter firstname`);
        }
        if (!!!lastname) {
          resolve(`Please enter lastname`);
        }
        if (!!!username) {
          resolve(`Please enter username`);
        }
        if (!!!password) {
          resolve(`Please enter password`);
        }
        if (
          password.search("/") !== -1 ||
          password.search(/\\/) !== -1 ||
          password.search(/\^/) !== -1 ||
          password.search("'") !== -1 ||
          password.search(",") !== -1 ||
          password.search('"') !== -1 ||
          password.search(/\[/) !== -1 ||
          password.search("]") !== -1 ||
          password.search("{") !== -1 ||
          password.search("}") !== -1 ||
          password.search(/\(/) !== -1 ||
          password.search(/\)/) !== -1
        ) {
          resolve(
            `The password must not contain the characters /\^'," [ ] { }( ) `
          );
        }
        resolve("OK");
      });
    },
    redata() {
      this.data = {
        username: "",
        password: "",
        firstname: "",
        lastname: "",
        email: "",
      };
    },
    async save() {
      try {
        let vali = await this.validate();
        if (vali !== "OK") {
          throw vali;
        }
        let mss = `ยืนยันการบันทึก / Confirm Recording?`;
        let confirm = await this.$serviceMain.showConfirmAlert(this, mss);
        if (confirm) {
          let data = this.data;
          let getAPI = await this.$serviceAPI.call_API(
            "post",
            `adminit/createuserlogin`,
            data,
            1
          );
          this.redata()
          this.$serviceMain.showSuccessAlert(this, "สำเร็จ / Complete");
        }
      } catch (error) {
        this.$serviceMain.showErrorAlert(
          this,
          error.message == undefined ? error : error.message
        );
      }
    },
  },
};
</script>

<style></style>
